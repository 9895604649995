// Refactored by j-funk into functional component 2023/10
// TODO: target for further refactoring.
//  This is a legacy component and has a significant amount of overlap with
//  ~/components/PasteBox/*
import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Col, Grid, ProgressBar, Row } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/withStyles';
import { useDispatch, useSelector } from 'react-redux';

import s from './PasteBoxTable.css';
import PasteForm from './PasteForm';
import SearchErrorMsg from '../SearchErrorMsg';
import TermCountWarning from '../TermCountWarning';
import ReviewMsg from '../ReviewMsg';
import ReviewList from '../ReviewList';
import ValidList from '../ValidList';
import {
  doMultiGeneSearch,
  getGeneListTSV,
  hasReservedUrlChars,
} from '../../../core/util';
import { saveText } from '../../../core/save';
import SearchExample from '../SearchExample';
import SearchDropdown from '../SearchDropdown';
import {
  COMMUNITY_MIN_THRESHOLD,
  MAX_GENE_QUERY_SIZE,
} from '../../../settings';
import {
  setCurrentSearchGenes,
  setCurrentSearchText,
} from '../../../actions/NetworkActions';
import FMDQueryComponent from '../../FMDQueryComponent';
import { useFMD } from '../../FMDQueryComponent/fmdMachine';
import ApplicationContext from '../../ApplicationContext';

function filterMultiMatch(geneList) {
  return geneList.filter(e => e.matches.length > 1);
}

function filterNoMatch(geneList) {
  return geneList.filter(e => e.matches.length === 0);
}

function filterSingleMatch(geneList) {
  return geneList.filter(e => e.matches.length === 1);
}

function getSelectedIndices(reviewList) {
  const selIdx = [];
  for (let i = 0; i < reviewList.length; i += 1) {
    selIdx.push(0);
  }
  return selIdx;
}

function filterGeneList(geneList) {
  const reviewList = filterMultiMatch(geneList);
  const validList = filterSingleMatch(geneList);
  const selIdx = getSelectedIndices(reviewList);
  const errorList = filterNoMatch(geneList);
  return { validList, reviewList, errorList, selIdx };
}

function PasteBoxTable({
  exampleSearch = [],
  flexWidth = false,
  handleTargetSelect = null,
  isModuleSearch = null,
  maxGenes = MAX_GENE_QUERY_SIZE,
  minGenes = COMMUNITY_MIN_THRESHOLD,
  onSubmit,
}) {
  const dispatch = useDispatch();
  const currentSearchGenes = useSelector(
    state => state.currentSearch.currentSearchGenes,
  );
  const [doingMultiGeneSearch, setDoingMultiGeneSearch] = useState(false);
  const [isPasteChanged, setIsPasteChanged] = useState(false);
  const [invalidText, setInvalidText] = useState(false);
  const [fmdMachineState] = useFMD();
  const bodyTag = fmdMachineState?.context?.bodyTag;
  // geneList is composed of: { validList, reviewList, errorList, selIdx }
  const [geneList, setGeneList] = useState(filterGeneList(currentSearchGenes));
  const context = useContext(ApplicationContext);

  // Replaces afterSearch()
  useEffect(() => {
    setGeneList(filterGeneList(currentSearchGenes));
  }, [currentSearchGenes]);

  const exactSearch = useCallback(
    async text => {
      const result = await doMultiGeneSearch(text, context);
      if (Array.isArray(result)) {
        dispatch(setCurrentSearchGenes(result));
      } else {
        dispatch(setCurrentSearchGenes([]));
      }
      setDoingMultiGeneSearch(false);
      setIsPasteChanged(false);
    },
    [context, dispatch],
  );

  const handlePasteChange = useCallback(
    text => {
      if (hasReservedUrlChars(text)) {
        setInvalidText(true);
        return;
      }
      setDoingMultiGeneSearch(true);
      setInvalidText(false);
      exactSearch(text);
    },
    [exactSearch],
  );

  const handleExampleClick = useCallback(
    (e, text) => {
      dispatch(setCurrentSearchText(text));
      handlePasteChange(text);
    },
    [dispatch, handlePasteChange],
  );

  const handleReviewChange = (index, value) => {
    const selIdx = [...geneList.selIdx];
    selIdx[index] = Number(value);
    setGeneList({ ...geneList, selIdx });
  };

  const handleSubmit = useCallback(() => {
    const { reviewList, validList, selIdx } = geneList;

    const reviewGenes = reviewList.map((e, i) => ({
      entrez: e.matches[selIdx[i]].entrez,
      standard_name: e.matches[selIdx[i]].standard_name,
    }));

    const validGenes = validList.map(e => ({
      entrez: e.matches[0].entrez,
      standard_name: e.matches[0].standard_name,
    }));

    let genes = reviewGenes.concat(validGenes);
    genes = genes.filter(
      (gene, idx, self) =>
        self.findIndex(el => gene.entrez === el.entrez) === idx,
    );

    onSubmit({ genes, bodyTag });
  }, [geneList, onSubmit, bodyTag]);

  const { errorList, reviewList, validList, selIdx } = geneList;

  const queryGeneCount = validList?.length + reviewList?.length;
  const isEligibleModuleQuery =
    queryGeneCount >= minGenes && queryGeneCount <= maxGenes;

  let alert = null;
  if (invalidText) {
    alert = (
      <Alert bsStyle="danger" style={{ width: '80%', margin: 'auto' }}>
        <i className="fa fa-exclamation-triangle" /> There are invalid
        characters in your gene list: {'{ }'} [ ] ~ : / | \ ? = ^ & % ` &apos;
        &quot; #. Please remove and try again.
      </Alert>
    );
  } else if (isModuleSearch && !isEligibleModuleQuery && queryGeneCount > 0) {
    alert = (
      <Alert bsStyle="warning" style={{ width: '80%', margin: 'auto' }}>
        <i className="fa fa-exclamation-circle" /> The number of eligible query
        genes must be at least {minGenes} and less than {maxGenes}.
      </Alert>
    );
  }

  let content = null;
  if (isEligibleModuleQuery && isModuleSearch) {
    const entrezList = [];

    if (reviewList?.length)
      entrezList.push(...reviewList.map((e, i) => e.matches[selIdx[i]].entrez));
    if (validList?.length)
      entrezList.push(...validList.map(e => e.matches[0].entrez));

    content = (
      <div className={s.searchDiv}>
        <FMDQueryComponent
          entrezList={entrezList}
          createSubmitButton={onSubmit => {
            return (
              <div className={s.submitButton}>
                <Button
                  block
                  bsStyle="primary"
                  onClick={() => {
                    onSubmit();
                    handleSubmit();
                  }}
                  disabled={isPasteChanged || bodyTag === null}
                >
                  {isPasteChanged ? (
                    <>
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ marginRight: '5px' }}
                      />
                      Updating
                    </>
                  ) : (
                    'Search'
                  )}
                </Button>
              </div>
            );
          }}
        />
      </div>
    );
  } else if (!geneList.length && doingMultiGeneSearch) {
    content = (
      <div className={s.searchDiv}>
        <ProgressBar
          active
          striped
          label="Validating query genes..."
          now={100}
        />
      </div>
    );
  } else if (!isModuleSearch && isEligibleModuleQuery && queryGeneCount > 0) {
    content = (
      <div className={s.searchDiv}>
        <Button
          className={s.submitButton}
          bsStyle="primary"
          onClick={handleSubmit}
        >
          Search
        </Button>
      </div>
    );
  }

  let heading = null;
  if (errorList.length || reviewList.length || validList.length) {
    const reviewGenes = reviewList.map((e, i) => e.matches[selIdx[i]]);
    const validGenes = validList.map(e => e.matches[0]);

    heading = (
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <div style={{ textAlign: 'left' }}>
          <h3>Search Results</h3>
        </div>
        <div
          style={{
            justifySelf: 'end',
            alignSelf: 'center',
          }}
        >
          <Button
            bsStyle="default"
            onClick={() => {
              const geneListContent = getGeneListTSV([
                ...reviewGenes,
                ...validGenes,
              ]);
              saveText(geneListContent, `geneList-${Date.now()}.tsv`);
            }}
          >
            <span>
              <i className="fa fa-download" style={{ paddingRight: '5px' }} />
              Download Gene List
            </span>
          </Button>
        </div>
      </div>
    );
  }

  const examples = exampleSearch.length > 0 && (
    <div className={s.exampleAlert}>
      <i className="fa fa-lightbulb-o" />
      {` Example searches: `}
      {exampleSearch.map((ex, idx) => (
        <SearchExample
          example={ex}
          key={`example-${idx}`}
          clickFn={handleExampleClick}
          isSomeText={Boolean(geneList.length)}
        />
      ))}
    </div>
  );
  const pasteForm = (
    <PasteForm
      setIsPasteChanged={setIsPasteChanged}
      onChange={handlePasteChange}
    />
  );
  const withSelect = (
    <Grid className={s.grid}>
      <Row>
        <Col className={s.column} xs={12} sm={12}>
          {examples}
        </Col>
      </Row>
    </Grid>
  );

  const withSelectContent = (
    <Grid className={s.grid}>
      <Row>
        <Col className={s.column} sm={3}>
          <SearchDropdown
            onTargetSelect={handleTargetSelect}
            target={isModuleSearch ? 'module' : 'gene'}
          />
        </Col>
        <Col className={s.column} sm={9}>
          <PasteForm
            setIsPasteChanged={setIsPasteChanged}
            onChange={handlePasteChange}
          />
        </Col>
      </Row>
    </Grid>
  );

  // `handleTargetSelect` is the function that is run if we are on the landing page
  //  if the user is on /module or /gene it should be `null`
  const topContent = handleTargetSelect ? withSelect : examples;
  const pasteContent = handleTargetSelect ? withSelectContent : pasteForm;

  return (
    <div className={s.root}>
      {topContent}
      <div className={s.pastebox}>{pasteContent}</div>
      <div className={s.exampleAlert}>{alert}</div>
      <div>{content}</div>

      <div style={{ margin: '50px auto 0', width: '90%' }}>
        {heading}
        <SearchErrorMsg data={errorList} />
        <TermCountWarning
          numValidSearchGenes={reviewList.length + validList.length}
          maxValidSearchGenes={maxGenes}
        />
        <ReviewMsg data={reviewList} />
        <ReviewList
          data={reviewList}
          selIdx={selIdx}
          flexWidth={flexWidth}
          onChange={handleReviewChange}
        />
        <ValidList data={validList} />
      </div>
    </div>
  );
}

PasteBoxTable.propTypes = {
  exampleSearch: PropTypes.arrayOf(PropTypes.object),
  flexWidth: PropTypes.bool,
  handleTargetSelect: PropTypes.func,
  isModuleSearch: PropTypes.bool.isRequired,
  maxGenes: PropTypes.number,
  minGenes: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  tissue: PropTypes.shape({ group: PropTypes.string }),
};

export default withStyles(s)(PasteBoxTable);
